<template>
    <el-dialog class="popup_dialog" title="新增合同" :visible.sync="isPopupAdd" :close-on-click-modal="false" :show-close="false" :destroy-on-close="true" width="600px">
        <el-form ref="formAdd" :model="formAdd" :rules="rules" label-position="top" size="small">
            <el-form-item label="企业名称：" prop="companyId">
                <el-select v-model="formAdd.companyId" placeholder="请选择企业名称" filterable>
                    <el-option v-for="(item, index) in companyList" :key="index" :label="item.corporateName" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="合同编号：" prop="number" class="half mrb4">
                <el-input v-model="formAdd.number" placeholder="请输入合同编号"></el-input>
            </el-form-item>
            <el-form-item label="合同有效期：" prop="date" class="half">
                <el-date-picker
                        v-model="formAdd.date"
                        type="daterange"
                        range-separator="~"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        value-format="yyyy-MM-dd" style="width: 220px;">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="类型：" prop="type">
                <el-radio-group v-model="formAdd.type" @change="typeAddChange">
                    <el-radio :label="1">图片</el-radio>
                    <el-radio :label="2">附件</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="上传图片：" prop="image" v-show="formAdd.type === 1">
                <el-upload
                        ref="uploadAddImg"
                        :action="uploadURL"
                        :before-upload="beforeUploadImage"
                        :on-success="imageUploadAdd"
                        :on-remove="imageRemoveAdd"
                        list-type="picture-card">
                    <i class="el-icon-plus"></i>
                </el-upload>
                <div class="el-upload__tip">支持扩展名：.jpg、.jpeg、.png格式，图片大小不超过10M</div>
            </el-form-item>

            <el-form-item label="上传附件：" prop="image" v-show="formAdd.type === 2">
                <el-upload
                        ref="uploadAddFile"
                        class="upload-demo"
                        :action="uploadURL"
                        :before-upload="beforeUploadFile"
                        :on-success="imageUploadAdd"
                        :on-remove="imageRemoveAdd">
                    <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
                <div class="el-upload__tip">上传附件大小不超过10M</div>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" :loading="submitLoading" @click="submitFormAdd('formAdd')">提 交</el-button>
                <el-button type="default" @click="toClose">取 消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
  import {postContract} from "../../../api/shop/contract";
  import {imageURL, uploadURL} from "../../../utils/config";
  import {beforeUploadImage, beforeUploadFile} from '../../../utils/method'

  export default {
    name: "userMenu",
    props: {
      isPopupAdd: {
        type: Boolean,
        default: false
      },
      companyList: {
        type: Array,
        default: () => {return []}
      }
    },
    data () {
      return {
        formAdd: {
          companyId: '',
          number: '',
          date: [],
          type: 1,
          image: []
        },
        rules: {
          companyId: [
            {required: true, message: '请选择企业名称', trigger: 'change'}
          ],
          number: [
            {required: true, message: '请输入合同编号', trigger: 'blur'}
          ],
          date: [
            {required: true, message: '请选择合同有效期', trigger: 'change'}
          ],
          image: [
            {required: true, message: '请上传合同附件', trigger: 'change'}
          ],
        },
        submitLoading: false,
        imageURL: imageURL,
        uploadURL: uploadURL,
        beforeUploadImage: beforeUploadImage,
        beforeUploadFile: beforeUploadFile
      }
    },
    methods: {
      imageUploadAdd (res, file) {
        if (res.code === 0) {
          this.formAdd.image.push({
            url: res.data,
            name: file.name
          })
        }
      },
      imageRemoveAdd (file, fileList) {
        let data = []
        fileList.forEach(item => {
          data.push({
            url: item.response.data,
            name: item.name
          })
        })
        this.formAdd.image = data
      },
      typeAddChange () {
        this.formAdd.image = []
        this.$refs.uploadAddImg.clearFiles()
        this.$refs.uploadAddFile.clearFiles()
      },
      submitFormAdd (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitLoading = true
            let companyName = ''
            let start = ''
            let end = ''

            companyName = this.companyList.filter(item => item.id === this.formAdd.companyId)[0].corporateName

            if (this.formAdd.date.length === 2) {
              start = this.formAdd.date[0]
              end = this.formAdd.date[1]
            }

            postContract({
              data: {
                companyId: this.formAdd.companyId,
                companyName: companyName,
                start: start,
                end: end,
                number: this.formAdd.number,
                type: this.formAdd.type,
                image: this.formAdd.image,
              }
            }).then(res => {
              this.submitLoading = false
              if (res.code === 0) {
                this.$message({
                  message: '合同添加成功',
                  type: 'success',
                  showClose: true,
                });
                this.toClose()
                this.$refs['formAdd'].resetFields()
                this.$emit('toAddSuccess')
              }
            }).catch(() => {
              this.submitLoading = false
            })
          }
        })
      },
      toClose () {
        this.$emit('close')
      }
    },
    components: {}
  }
</script>
